import StartIcon from "assets/images/svg/price-icon-1.svg";
import IndividualIcon from "assets/images/svg/price-icon-2.svg";
import TeamIcon from "assets/images/svg/price-icon-3.svg";

export const basic = [
  {
    image: StartIcon,
    plantitle: "Basic",
    description: ` `,
    monthly: 0,
    yearly: 0,
    buttonText: "Get Started For Free",
    buttonClass: "outline-primary",
    featureHeading: " ",
    features: [
      { feature: "Postings", status: true },
      { feature: `Dedicated Account Manager`, status: true },
      { feature: `Instant Posting`, status: true },
      { feature: "Listing in first page", status: false },
      { feature: "AI Recommendation", status: true },
      { feature: "Push Certifiied Interns By internshipgate", status: true },
      { feature: "Email Notification", status: true },
      { feature: "Chat Support", status: true },
      { feature: "Phone Call Support", status: true },
    ],
  },
];

export const payAsYouGo = [
  {
    image: IndividualIcon,
    plantitle: "Pay as go ",
    description: ` `,
    monthly: 999,
    yearly: 99,
    buttonText: "Select Plan",
    buttonClass: "primary",
    featureHeading: " ",
    features: [
      { feature: "Postings", status: false },
      { feature: `Dedicated Account Manager`, status: true },
      { feature: `Instant Posting`, status: true },
      { feature: "Listing in first page", status: true },
      { feature: "AI Recommendation", status: true },
      { feature: "Push Certifiied Interns By internshipgate", status: true },
      { feature: "Email Notification", status: false },
      { feature: "Chat Support", status: true },
      { feature: "Phone Call Support", status: true },
    ],
  },
];

export const monthly = [
  {
    image: TeamIcon,
    plantitle: "Monthly",
    description: ` `,
    monthly: 1999,
    yearly: 199,
    buttonText: "Select Plan",
    buttonClass: "outline-primary",
    featureHeading: " ",
    features: [
      { feature: "Postings", status: true },
      { feature: `Dedicated Account Manager`, status: true },
      { feature: `Instant Posting`, status: true },
      { feature: "Listing in first page", status: true },
      { feature: "AI Recommendation", status: true },
      { feature: "Push Certifiied Interns By internshipgate", status: true },
      { feature: "Email Notification", status: true },
      { feature: "Chat Support", status: true },
      { feature: "Phone Call Support", status: true },
    ],
  },
];

export const yearly = [
  {
    image: TeamIcon,
    plantitle: "Yearly",
    description: ` `,
    monthly: 9999,
    yearly: 199,
    buttonText: "Select Plan",
    buttonClass: "outline-primary",
    featureHeading: " ",
    features: [
      { feature: "Postings", status: true },
      { feature: `Dedicated Account Manager`, status: true },
      { feature: `Instant Posting`, status: true },
      { feature: "Listing in first page", status: true },
      { feature: "AI Recommendation", status: true },
      { feature: "Push Certifiied Interns By internshipgate", status: true },
      { feature: "Email Notification", status: true },
      { feature: "Chat Support", status: true },
      { feature: "Phone Call Support", status: true },
    ],
  },
];

export const PricingPlansData = [basic, payAsYouGo, monthly, yearly];

export default PricingPlansData;
